import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["klaviyoSyncRunning", "klaviyoRequiresSetup"];

  connect() {
    fetch("/integrations/klaviyo/banner_status")
      .then(response => response.json())
      .then(data => {
        if (data.list_or_segment_sync_running && this.hasKlaviyoSyncRunningTarget) {
          this.klaviyoSyncRunningTarget.classList.remove("hidden");
          return;
        }

        if (data.klaviyo_requires_setup && this.hasKlaviyoRequiresSetupTarget) {
          this.showAndAttachDismissible(this.klaviyoRequiresSetupTarget);
        }
      })
      .catch(error => console.error("Error loading Klaviyo banner:", error));
  }

  showAndAttachDismissible(element) {
    element.classList.remove("hidden");
    element.setAttribute("data-controller", "dismissible");
  }
}
