import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="changed-custom-dashboard-state"
export default class extends Controller {
  static values = {
    customDashboardId: Number,
    state: String
  }

  connect() {
    const state = this.stateValue

    if (state === 'editable') {
      unlockDashboardCards()
      if (!window.location.href.endsWith("/new")) {
        updateUrlToEdit()
      }
    } else {
      // Fixed, uneditable dashboard
      updateUrlToShow(this.customDashboardIdValue)
      lockDashboardCards()
    }

    initUpdateCompareDatesOnFormSubmit();
  }
}

const updateUrlToEdit = () => {
  // Update URL to "edit"
  const currentURL = window.location.href
  if (!currentURL.endsWith("/edit")) {
    const newURL = currentURL + '/edit'
    window.history.pushState({}, "", newURL);
  }
}

const updateUrlToShow = (customDashboardId) => {
  // Update URL back to show
  const currentURL = window.location.href
  let newURL;

  if (currentURL.endsWith("/edit")) {
    newURL = currentURL.replace(/(\/edit$)/, '')
  } else if (currentURL.endsWith("/duplicate")) {
    newURL = currentURL.replace(/([0-9]+\/duplicate$)/, customDashboardId)
  } else if (currentURL.includes('new?template=')) {
    newURL = currentURL.replace(/new\?template=([0-9]+$)/, customDashboardId)
  } else if (currentURL.endsWith("/new")) {
    newURL = currentURL.replace(/(new$)/, customDashboardId)
  }

  window.history.pushState({}, "", newURL);
}

const unlockDashboardCards = () => {
  // Render dashboard cards editable
  const grid = document.getElementById('grid_stack_grid')
  if (grid && grid.gridstack) {
    grid.gridstack.enable()
  }

  const gridStackItems = document.querySelectorAll('.grid-stack-item')
  gridStackItems.forEach(function(item) {
    item.classList.add('grid-stack-item-custom--unlocked')
  })
}

const lockDashboardCards = () => {
  // Return to "show" view; uneditable state
  const grid = document.getElementById('grid_stack_grid')
  if (grid && grid.gridstack) {
    grid.gridstack.disable()
  }

  const gridStackItems = document.querySelectorAll('.grid-stack-item')
  gridStackItems.forEach(function(item) {
    item.classList.remove('grid-stack-item-custom--unlocked')
  })
}

const getCompareToDate = (dateFrom, dateTo, compareFilter) => {
  let comparisonDateFrom, comparisonDateTo;
  
  switch (compareFilter) {
    case "year_period":
      comparisonDateTo = new Date(dateTo);
      comparisonDateTo.setFullYear(comparisonDateTo.getFullYear() - 1);

      comparisonDateFrom = new Date(dateFrom);
      comparisonDateFrom.setFullYear(comparisonDateFrom.getFullYear() - 1);
      break;

    case "year_period_weekday":
      const yearFrom = new Date(dateFrom);
      yearFrom.setFullYear(yearFrom.getFullYear() - 1);
      
      const targetFromDay = dateFrom.getDay();
      const yearFromDay = yearFrom.getDay();
      
      // Calculate days difference to match weekday
      const fromDaysDiff = yearFromDay <= targetFromDay
        ? targetFromDay - yearFromDay
        : 7 - (yearFromDay - targetFromDay);
      
      comparisonDateFrom = new Date(yearFrom);
      comparisonDateFrom.setDate(yearFrom.getDate() + fromDaysDiff);
      
      const durationDays = Math.floor((dateTo - dateFrom) / (1000 * 60 * 60 * 24));
      comparisonDateTo = new Date(comparisonDateFrom);
      comparisonDateTo.setDate(comparisonDateFrom.getDate() + durationDays);
      break;

    case "previous_period_weekday":
      const targetToDay = dateTo.getDay();
      let compTo = new Date(dateFrom);
      compTo.setDate(compTo.getDate() - 1);
      
      // Adjust end date to match weekday
      while (compTo.getDay() !== targetToDay) {
        compTo.setDate(compTo.getDate() - 1);
      }
      
      comparisonDateTo = compTo;
      const timeframeWeekday = Math.floor((dateTo - dateFrom) / (1000 * 60 * 60 * 24));
      comparisonDateFrom = new Date(compTo);
      comparisonDateFrom.setDate(compTo.getDate() - timeframeWeekday);
      break;

    case "custom_period":
      comparisonDateTo = new Date(dateTo);
      comparisonDateFrom = new Date(dateFrom);
      break;

    default: // previous_period
      const timeframe = Math.floor((dateTo - dateFrom) / (1000 * 60 * 60 * 24)) + 1;
      comparisonDateFrom = new Date(dateFrom);
      comparisonDateFrom.setDate(comparisonDateFrom.getDate() - timeframe);

      comparisonDateTo = new Date(dateFrom);
      comparisonDateTo.setDate(comparisonDateTo.getDate() - 1);
      break;
  }

  return { comparisonDateFrom, comparisonDateTo };
}

const initUpdateCompareDatesOnFormSubmit = () => {
  $('#custom_dashboard_timeperiod_select').on('submit', function (e) {
    let date_from = new Date($(this).find('#date_from').val())
    let date_to = new Date($(this).find('#date_to').val())
    let compare_to = $(this).find('#compare_to_period').val()
    let {comparisonDateFrom, comparisonDateTo} = getCompareToDate(date_from, date_to, compare_to)

    let formattedDates = [comparisonDateFrom, comparisonDateTo].map(date => {
      return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
    }).join(' - ');

    $(this).find('#compare_to_dates').val(formattedDates);
  })
}