import consumer from "./consumer"

consumer.subscriptions.create("SyncStatusChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        // Called when there's incoming data on the websocket for this channel

        if ($('.progress-text').length > 0)
        {
            if( data["report_loaded_percent"]) {
                $('.progress-text').text(`Loading... ${data["report_loaded_percent"]}`);
                $(".cbox--skeleton").removeClass (function (index, className) {
                    return (className.match (/(^|\s)skeleton-load-percent-\S+/g) || []).join(' ');
                });

                let loadPercentClass = data["report_loaded_percent"]
                // Add a leading zero if the report_loaded_percent is less than 10% so that the CSS works
                if (loadPercentClass.length === 2) {
                    loadPercentClass = "0" + loadPercentClass
                }

                $('.cbox--skeleton').addClass(`skeleton-load-percent-${loadPercentClass}`);
                // currently a hack to reuse this for reporting progress on showing a report
                // TODO: make this into a separate entity
                return
            }
        }


        if ($('#sync-status-table').length > 0) {
        $.get({
            url: '/data-sync-status/sync-status',
            data: data,
            success: function (sync_data) {
                $('#sync-status').html(sync_data)
            }
        })
        $.get({
            url: '/data-sync-status/sync-status-details',
            success: function (sync_data) {
                $('#sync-status-table').html(sync_data)
            }
        })
        }

        const performPulseEffectForSyncBanner = () => {
            const banner = $('#sync-in-progress-notification-banner');

            banner.css('background-color','#4D63D1');

            setTimeout(function () {
                banner.css('background-color','#2E45B2');
            }, 1500);
        }


        if ($('#sync-in-progress-notification-banner').length > 0)
        {

            if( data["sync_complete"])
            {
                var auto_refreshed_reports = ['dashboard', 'subscriptions_dashboard', 'subscriptions_report']
                var data_controller = $('main .container').attr('data-controller');
                var data_action = $('main .container').attr('data-action');
                var data_params = $('main .container').attr('data-params');
                if (auto_refreshed_reports.includes(data_controller)) {
                    $.post({
                        url: '/' + data_controller + '/refresh-report?report=' + data_action,
                        data: {report_params: data_params}
                    })
                }

                $('#progress-sync-icon').removeClass("spinner--slower-with-pulse");
                $('#progress-sync-icon').addClass("icon-checkmark");
                $('#progress-sync-icon').removeClass("icon-sync");
                $('#progress-sync-stat').text("Data refresh complete");
                $('#progress-sync-description').text("");
                performPulseEffectForSyncBanner();
                $('#progress-sync-tooltip').remove();

                return
            }

            var percent_loaded = data["orders_loaded_number"]
            if (percent_loaded == 100)
            {
                showSyncBannerWithCssEffects();
                $('#progress-sync-icon').removeClass("spinner--slower-with-pulse");
                $('#progress-sync-icon').addClass("icon-checkmark");
                $('#progress-sync-icon').removeClass("icon-sync");
                performPulseEffectForSyncBanner();
                $('#progress-sync-tooltip').remove();
            }
            if (percent_loaded < 100)
            {
                showSyncBannerWithCssEffects();
                $('#progress-sync-icon').addClass("spinner--slower-with-pulse");
                $('#progress-sync-icon').removeClass("icon-checkmark");
                $('#progress-sync-icon').addClass("icon-sync");

            }
            $('#progress-sync-stat').text(data["orders_loaded_percent_text"]);
            $('#progress-sync-description').text('');



        }

      if ($('#sync-in-progress-notification-banner').length > 0)
      {
        if (data["scheduled"])
        {
            showSyncBannerWithCssEffects();
            $('#progress-sync-icon').removeClass("spinner--slower-with-pulse");
            $('#progress-sync-icon').addClass("icon-checkmark");
            $('#progress-sync-icon').removeClass("icon-sync");
            $('#progress-sync-description').text('');
        }
        else
        {
          if (data["job_progress_percentage"] < 100)
          {
              showSyncBannerWithCssEffects();
              $('#progress-sync-icon').addClass("spinner--slower-with-pulse");
              $('#progress-sync-icon').removeClass("icon-checkmark");
              $('#progress-sync-icon').addClass("icon-sync");
              $('#progress-sync-tooltip').remove();

          }
          else if (data["job_progress_percentage"] == 100)
          {
              showSyncBannerWithCssEffects();
              $('#progress-sync-icon').removeClass("spinner--slower-with-pulse");
              $('#progress-sync-icon').addClass("icon-checkmark");
              $('#progress-sync-icon').removeClass("icon-sync");
              performPulseEffectForSyncBanner();
              $('#progress-sync-tooltip').remove();
          }
        }

        if (data["job_status_text"])
        {
            $('#progress-sync-stat').text(data["job_status_text"]);

        }
       if (data["job_status_text"])
       {
           $('#progress-sync-description').text(data["job_description_text"]);

       } else {
           $('#progress-sync-description').text('');
       }

      }


    }
})
